import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import {showWarningMessage} from "@/core/utils/alerts/warning";
import {showSuccessMessage} from "@/core/utils/alerts/sucess";
// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const UPDATE_PASSWORD = 'updateUser'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const CONFIRM_AUTH = 'setConfirmAuth'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'
export const SET_ERROR_MESSAGE = 'setMessage'

const state = {}

const getters = {}

const actions = {

  [REGISTER] (context, params)
  {
    return ApiService.post('/auth/register', params).then(response =>
    {
      if (response.status === 201)
      {
        showSuccessMessage('Sucesso!', 'Cadastro realizado.');
        return true;
      }
      return false;
    })
        .catch(error => {
          console.error('Erro ao criar usuário', error);
        });
  },

  async [LOGIN] (context, credentials){
    return (async () => {
      try {
        const response = await ApiService.post('/auth/login', credentials);
        if (response.status === 201) {
          context.commit(SET_AUTH, response.data);
          return true;
        }
      } catch (error) {
        return false;
      }
    })();
  },

  [LOGOUT] (context) {
    context.commit(PURGE_AUTH)
    return true;
  },

  [VERIFY_AUTH] (context){
    if (JwtService.getToken()) {
      console.log('TOKEN ATUAL: ' + JwtService.getToken())
      return ApiService.get('auth/verify', {})
        .then(({ data }) => {
          console.log('Usuario logado')
          context.commit(CONFIRM_AUTH)
          return state.isAuthenticated
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response)
        })
    }
  },

  async updatePassword (context, payload){
    return (async () => {
      try {
        const response = await ApiService.post('/auth/password/reset/change', payload);
        if (response.status === 201) {
          return true;
        }
      } catch (error) {
        return false;
      }
    })();
  },
  async passwordReset (context, credentials){
    return (async () => {
      try {
        const response = await ApiService.post('/auth/password/reset/request', credentials);
        if (response.status === 201) {
          return true;
        }
      } catch (error) {
        return false;
      }
    })();
  },
}

const mutations = {
  [SET_ERROR] (state, error) {
    console.log('Erro encontrado: ' + error)
    state.errors = error
  },
  [SET_ERROR_MESSAGE] (state, msg) {
    console.log('Mensagem de erro: ' + msg)
    state.errors = error
  },

  [SET_AUTH] (state, user) {
    state.isAuthenticated = true;
    JwtService.saveToken(user.token)
  },

  [CONFIRM_AUTH] (state) {
    state.isAuthenticated = true
  },
  [SET_PASSWORD] (state, password) {
    state.user.password = password
  },
  [PURGE_AUTH] (state) {
    console.log('Ops, sessão encerrada. Faça login novamente')
    state.isAuthenticated = false
    JwtService.destroyToken()
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
}
